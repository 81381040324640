<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      {{ ballot && ballot.title ? `Voting Results for "${ballot.title}"` : "Loading.." }}
    </template>

    <template #cardBody>

      <!-- START VotingResults  -->
      <div
        class="d-flex flex-column"
        style="height: 100%"
      >
        <v-container
          class="px-6 py-4"
        >

          <!-- CSV Export Votes -->
          <v-row
            class="mb-4"
            no-gutters
          >
            <v-spacer/>
            <v-col cols="auto">
              <div>
                <v-btn
                  @click="openBatchDeleteModal"
                  color="primary"
                  :disabled="voterSelectedForBatchDelete == null"
                  block
                >
                  Delete All Votes Cast By
                </v-btn>
              </div>
            </v-col>
            <v-col cols="auto">
              <div class="d-flex align-center ml-2">
                <v-select
                  v-model="voterSelectedForBatchDelete"
                  :items="voterList"
                  item-text="fullName"
                  return-object
                  class="my-0 py-0"
                  style="width: 250px;"
                  hide-details
                />
              </div>
            </v-col>
            <v-divider
              class="mx-2"
              vertical
            />
            <v-col cols="auto">
              <div>
                <v-btn
                  @click="exportVotes()"
                  color="primary"
                  block
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-table-large
                  </v-icon>
                  Export Votes
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- VIEW TYPES -->
          <v-row no-gutters class="mb-2">            
            <v-spacer/>
            <v-col cols="auto" class="mr-4">
              <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <div class="d-flex align-center">
                  <div class="bold">{{ viewType === 'nominees' ? 'View by Awards' : 'View by Voters' }}</div>
                  <!-- <div style="font-size: 0.6rem;">({{ vote.voter.school }})</div> -->
                </div>
              </div>
            </v-col>
            <v-col cols="auto">
              <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="viewType = 'nominees'"
                      class="bg-blue-grey lighten-5"
                      icon
                    >
                      <v-icon   
                        color="blue-grey darken-1"
                      >
                        mdi-ballot
                      </v-icon>
                    </v-btn>
                  </template>
                  By Award View
                </v-tooltip>
                <!--  -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="viewType = 'voters'"
                      icon
                    >
                      <v-icon   
                        color="blue-grey darken-1"
                      >
                        mdi-vote
                      </v-icon>
                    </v-btn>
                  </template>
                  By Voter View
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <!-- START Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && ballot.awards && viewType === 'nominees'"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(table, index) in tables"
              :key="index"
            >
              <v-expansion-panel-header>
                <div class="d-flex justify-space-between">
                  <h4>{{ table.award.awardType.title }} <small v-if="table.award.customVotes">(Custom Points)</small></h4>
                  <h4 class="px-4">
                    ({{ votesCountsTallied(table) }} Vote{{ votesCountsTallied(table) != 1 ? 's' : '' }} Tallied)
                  </h4>
                </div>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content class="panel-with-no-padding">
                <!-- START Award Nominations Table -->
                <v-data-table
                  :headers="resultsHeaders"
                  :items="table.award.nominations"
                  :items-per-page="5"
                  :expanded.sync="table.expanded"
                  class="mt-2"
                  show-expand
                  dense
                >
                  <template v-slot:[`item.fullName`]="{ item }">
                    <td class="font-weight-medium">
                      <div class="d-flex align-center">
                        <div class="mr-2">{{ item.fullName }}</div>
                        <div class="text-caption">({{ item.teamName }})</div>
                      </div>
                    </td>
                  </template>

                  <template v-slot:[`item.points`]="{ item }">
                    <td>{{ item.points }}</td>
                  </template>

                  <template v-slot:expanded-item="{ item, headers }">
                    <td
                      :colspan="headers.length"
                      class="pa-0"
                      style="background-color: #f8f8f8;"
                    >
                      <div
                        v-if="item.votes.length == 0"
                        class="px-4 py-1"
                      >
                        No votes found for this nomination.
                      </div>
                      
                      <!-- START Vote Rows - Expanded Inline With Border -->
                      <table style="width: 100%;">
                        <tr
                          v-for="(vote, voteIndex) in item.votes"
                          :key="voteIndex"
                          class="px-4 py-1"
                        >
                          <td
                            class="px-4 table-font"
                            style="width: 40%"
                          >
                            <div class="d-flex align-center">
                              <div class="mr-2">{{ `${vote.voter.firstName} ${vote.voter.lastName}` }}</div>
                              <div style="font-size: 0.6rem;">({{ vote.voter.school }})</div>
                            </div>
                          </td>
                          <td
                            v-if="table.award.customVotes"
                            class="px-4 table-font"
                            style="width: 35%"
                          >
                            {{customPointCounter(table.award, vote.rank+1)}}
                          </td>
                          <td v-else
                            class="px-4 table-font"
                            style="width: 35%"
                          >
                            {{ vote.points }}
                          </td>
                          <td
                            class="px-4 table-font"
                            style="width: 24%"
                          >
                            <div class="d-flex align-center justify-space-between">
                              <div class="table-font">{{ formatDate(vote.createdOn) }}</div>
                              <div>
                                <v-btn
                                  @click="openDeleteModal(vote, item)"
                                  color="red accent-2"
                                  icon
                                  x-small
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <!-- END Vote Rows - Expanded Inline With Border -->
                    </td>
                  </template>
                </v-data-table>
                <!-- END Award Nominations Table -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Expansion Panels -->


          <!-- START Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && ballot.awards && viewType === 'voters'"
            multiple
            focusable
          >          
            <v-expansion-panel
              v-for="(table, index) in tablesVoters"
              :key="`voters${-index}`"
            >
              <v-expansion-panel-header>
                <div class="d-flex justify-space-between">
                  <div class="d-flex align-center">
                    <h4 class="mr-2">{{ table.votes.nominations[0].votesByAward[0].voter.fullName }} </h4>
                    <div class="text-caption">({{ table.votes.nominations[0].votesByAward[0].voter.school }})</div>
                  </div>

                  <h4 class="px-4">
                    <!-- ({{ votesCountsTallied(table) }} Vote{{ votesCountsTallied(table) != 1 ? 's' : '' }} Tallied) -->
                    ({{ getComputedVotes(index) }} of {{ getTotalVotes(index) }} votes)
                  </h4>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="panel-with-no-padding">
                <v-data-table
                  :headers="resultsHeaders"
                  :items="table.votes.nominations"
                  :items-per-page="5"
                  :expanded.sync="table.expanded"
                  class="mt-2"
                  show-expand
                  dense
                >
                  <template v-slot:[`item.fullName`]="{ item }">

                    <td class="font-weight-medium">
                      <div class="d-flex align-center">
                        <div class="mr-2">{{ getAward(item.awardId) }}</div>
                        <div style="font-size: 0.6rem;">({{ item.votesByAward.length }} of {{ item.numVotesAllowed }})</div>
                      </div>
                    </td>
                  </template>

                  <template v-slot:[`item.points`]="{ item }">
                    <td>{{ item.points }}</td>
                  </template>

                  <template v-slot:expanded-item="{ item, headers }">
                    <td
                      :colspan="headers.length"
                      class="pa-0"
                      style="background-color: #f8f8f8;"
                    >
                      <div
                        v-if="!item.awardId"
                        class="px-4 py-1"
                      >
                        No votes found for this nomination.
                      </div>
                      
                      <table style="width: 100%;">
                        <tr
                          v-for="(vote, voteIndex) in item.votesByAward"
                          :key="voteIndex"
                          class="px-4 py-1"
                        >
                          <td
                            class="px-4 table-font"
                            style="width: 40%"
                          >
                            <div class="d-flex align-center">
                              <div class="mr-2">{{ `${vote?.nominationVoted?.firstName} ${vote?.nominationVoted?.lastName}` }}</div>
                              <div style="font-size: 0.6rem;">({{ vote?.nominationVoted?.teamName }})</div>
                            </div>
                          </td>
                          <td
                            v-if="vote.customVotes"
                            class="px-4 table-font"
                            style="width: 35%"
                          >
                            {{ customPointCounter(vote, vote.rank+1) }}
                          </td>
                          <td
                            v-else
                            class="px-4 table-font"
                            style="width: 35%"
                          >
                            {{ vote.points }}
                          </td>

                          <td
                            class="px-4 table-font"
                            style="width: 24%"
                          >
                            <div class="d-flex align-center justify-space-between">
                              <div class="table-font">{{ formatDate(vote.createdOn) }}</div>
                              <div>
                                <v-btn
                                  @click="openDeleteModal({id : vote.id}, item)"
                                  color="red accent-2"
                                  icon
                                  x-small
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
              
              
              
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Expansion Panels -->
          <div v-if="tablesVoters.length === 0 && viewType === 'voters'">
            No votes found for this award.

          </div>

        </v-container>

        <!-- START Page Actions -->
        <v-card-actions
          class="px-6 py-4"
          style="margin-top: auto;"
        >
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Back
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->

      </div>
      <!-- END VotingResults -->

      <!-- Batch Vote Deletion Modal -->
      <app-action-modal
        @cancel="batchDeleteModal = false"
        @action="batchDelete()"
        :value="batchDeleteModal"
        :loading="batchDeleteLoading"
        :header-text="`Delete all votes by ${voterSelectedForBatchDelete == null ? '' : voterSelectedForBatchDelete.fullName}`"
        :warning-text="`Are you sure you want to delete all votes by ${voterSelectedForBatchDelete == null ? '' : voterSelectedForBatchDelete.fullName} for this ballot? This action cannot be undone.`"
      />

      <!-- Vote Deletion Modal -->
      <app-action-modal
        @cancel="deleteModal = false"
        @action="deleteVote(voteUpForDeletion, nominationOfVoteUpForDeletion)"
        :value="deleteModal"
        :loading="voteDeleteLoading"
        :header-text="`Delete vote by ${voteUpForDeletion != null ? voteUpForDeletion.voterName : ''}`"
        warning-text="Are you sure you want to delete this vote? This action cannot be undone."
      />

    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppActionModal from "@/components/app/AppActionModal.vue"

import Controllers from "@/data/controllers"
import CsvClient from "@/data/CsvClient"

import moment from 'moment-timezone'

export default {
  name: "VotingResults",

  components: {
    ViewCardTemplate,
    AppActionModal,
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    loaded: false,
    voterSelectedForBatchDelete: null,    
    ballot: null,
    tables: [],
    tablesVoters: [],
    resultsHeaders: [
      {
        text: "Nominee",
        align: "start",
        width: "40%",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Points",
        align: "start",
        width: "35%",
        sortable: true,
        value: "points",
      },
      {
        text: "Show Votes",
        align: "end",
        width: "24%",
        sortable: false,
        value: 'data-table-expand'
      },
    ],
    batchDeleteModal: false,
    batchDeleteLoading: false,
    deleteModal: false,
    voteUpForDeletion: null,
    nominationOfVoteUpForDeletion: null,
    voteDeleteLoading: false,
    viewType: "nominees"
  }),

  computed: {
    voterList() {
      const voterSet = new Set()
      this.tables.forEach(table => {
        table.award.nominations.forEach(nomination => {
          nomination.votes.forEach(vote => {
            voterSet.add(vote.voter)
          })
        })
      })

      return Array.from(voterSet)
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    }
  },

  methods: {
    customPointCounter(award, rank){
      return award.voteRank.find(vR => vR.rank == rank).points
    },
    sumarizedCustomPoints(award, votes) {
      let sum = 0
      votes.forEach(v => {
        sum += award.voteRank.find(vR => vR.rank == v.rank + 1).points
      })
      return sum
    },
    navigateBack() {
      this.$router.go(-1)
    },

    formatDate(date) {
      return moment.utc(date).tz("America/New_York").format("MM/DD/YY hh:mm A")
    },

    openBatchDeleteModal() {
      this.batchDeleteModal = true
    },

    batchDelete() {
      this.batchDeleteLoading = true
      
      const userId = this.voterSelectedForBatchDelete.id
      const res = Controllers.VoteController.AdminDeleteVotes(userId, this.ballotId)

      if(res && !res.hasError) {
        // Remove votes locally
        this.tables.forEach(table => {
          table.award.nominations.forEach(nomination => {
            nomination.votes = nomination.votes.filter(v => v.voter.id != this.voterSelectedForBatchDelete.id)
            nomination.points = this.votesTallied(nomination.votes)
          })
        })

        // Re-order nominations
        this.tables.forEach(table => {
          table.award.nominations.sort((a, b) => b.points - a.points)
        })

        this.$root.showSnackbar(`Votes by ${this.voterSelectedForBatchDelete.fullName} deleted successfully!`, "success", 5000)
      }
      else {
        // Error handling
        this.$root.showSnackbar(`Failed to delete votes by ${this.voterSelectedForBatchDelete.fullName}.!`, "error", 5000)
      }

      this.voterSelectedForBatchDelete = null
      this.batchDeleteLoading = false
      this.batchDeleteModal = false
    },

    openDeleteModal(vote, nomination) {
      this.voteUpForDeletion = vote
      this.nominationOfVoteUpForDeletion = nomination
      this.deleteModal = true
    },

    // eslint-disable-next-line no-unused-vars
    async deleteVote(vote, nomination) {
      this.voteDeleteLoading = true
      
      const deleteRes = await Controllers.VoteController.AdminDeleteVote(vote.id)

      this.getBallot()
      
      if(deleteRes && !deleteRes.hasError) {
        // nomination.votes.splice(nomination.votes.indexOf(vote), 1)
        // nomination.points = this.votesTallied(nomination.votes)

        // Re-order nominations
        this.tables.forEach(table => {
          table.award.nominations.sort((a, b) => b.points - a.points)
        })

        this.$root.showSnackbar('Vote deleted successfully!', "success", 5000)
      }
      else this.$root.showSnackbar('Failed to delete vote.', "error", 5000)

      this.voteDeleteLoading = false
      this.deleteModal = false
      this.voteUpForDeletion = null
      this.nominationOfVoteUpForDeletion = null
    },

    exportVotes() {
      const rows = []
      this.tables.forEach(table => {
        table.award.nominations.forEach(nomination => {
          nomination.votes.forEach(vote => {
            rows.push({
              VoterName: `${vote.voter.firstName} ${vote.voter.lastName}`,
              Points: vote.points,
              VoteDate: this.formatDate(vote.createdOn),
              Nominee: !nomination.fullName.includes(", ") ? nomination.fullName : `${nomination.fullName.split(", ")[1]} ${nomination.fullName.split(", ")[0]}`,
              TeamName: nomination.teamName,
              Award: table.award.awardType.title,
            })
          })
        })
      })

      CsvClient.Write(rows, `VotingResults-${this.ballot.title}`)
    },

    votesTallied(votes) {
      return votes.reduce(
        (total, vote) => total + vote.points,
        0
      )
    },

    votesCountsTallied(table) {
      return table.award.nominations.reduce(
        (total, nomination) => total + nomination.votes.length,
        0
      )
    },

    getAwardInfo( itemId ) {    
      let award = '';
      // this.ballot.awards.forEach( a => a.nominations.forEach( n => n.id === itemId ? award = a.awardType.title : null))
      this.ballot.awards.find( a => a.id === itemId ? award = a : null)
      return award;
    },

    getAward( itemId ) {    
      let award = '';
      // this.ballot.awards.forEach( a => a.nominations.forEach( n => n.id === itemId ? award = a.awardType.title : null))
      this.ballot.awards.find( a => a.id === itemId ? award = a.awardType.title : null)
      return award;
    },
    

    getComputedVotes(index) {
      return this.tablesVoters[index].votes?.nominations?.reduce( (total, nomination) => total + nomination.votesByAward?.length, 0);
    },

    getTotalVotes(index) {
      return this.tablesVoters[index].votes.nominations.reduce( (total, nomination) => total + nomination.numVotesAllowed, 0)
    },

    async getBallot() {
      const ballotRes = await Controllers.BallotController.AdminGetBallot(this.ballotId)

      if(ballotRes && !ballotRes.hasError) {
        const ballot = ballotRes.data

        const noVotes = [];
        
        const votes = ballot.awards
          .map(a => a.results.map(r => {
            if (r.votes.length == 0) noVotes.push({...r, numVotesAllowed: a.numVotesAllowed});
            return r.votes.map(v => { 
              return {
                ...v,
                createdOn: new Date(v.createdOn),
                voter: r.voter,
                points: ballot.rankVotes
                ? a.numVotesAllowed - v.rank
                : 1,
              }
            })
          }))
          .flat() // Flatten away the distinction between awards.
          .flat() // Flatten away the distinction between results.
        
          // Need this for the Delete All Votes Cast By v-select.
          votes.forEach(vote => vote.voter.fullName = `${vote.voter.firstName} ${vote.voter.lastName}`)

        const tables = ballot.awards.map(a => {
          return {
            award: {
              ...a,
              nominations: a.nominations.map(n => {
                return {
                  ...n,
                  fullName: `${n.firstName} ${n.lastName}`,
                  customVotes: n.customVotes,
                  votes: votes.filter(v => v.nominationId == n.id).sort((a, b) => b.points - a.points),
                  points: a.customVotes ? this.sumarizedCustomPoints(a, n.votes) : this.votesTallied(votes.filter(v => v.nominationId == n.id)),
                }
              })
              .sort((nomA, nomB) => nomB.points - nomA.points)
            },
            expanded: [],
          }
        })

        let arrayByVoters = [];      
        votes.forEach(vote => arrayByVoters.find( v => v.id === vote.voter.id) 
        ? arrayByVoters.find( item => item.id === vote.voter.id).v.push({...vote})
        : arrayByVoters.push({ id: vote.voter.id, v: [{...vote}]}));
        
        let getNominationVoted = (n) => {
          let person;
          ballot.awards.forEach( item => item.nominations.find( el => { if (el.id === n.nominationId) person = el}));
          return person;
        }   

        let getNumVotesByAward = (n) => {
          return ballot.awards.find(el => el.id === n);
        }        

        let tablesVoters = arrayByVoters.map(a => {
          return {
            votes: {
              ...a,
              fullName: `${a.v[0].voter.firstName} ${a.v[0].voter.lastName}`,
              v: a.v.map(n => {
                return {
                  ...n,
                  nominationVoted: getNominationVoted(n),
                  awardId: getNominationVoted(n)?.awardId ? getNominationVoted(n)?.awardId : null,
                  numVotesAllowed: getNumVotesByAward(getNominationVoted(n)?.awardId)?.numVotesAllowed,
                  customVotes: getNumVotesByAward(getNominationVoted(n)?.awardId)?.customVotes,
                  voteRank: getNumVotesByAward(getNominationVoted(n)?.awardId)?.voteRank,
                }
              })
            },
          }
        })

        let tableVotersCombined = [];
        let tableVotersCombined2 = [];
        
        tablesVoters.forEach( (item,index) => {
          item.votes.v.forEach( (vote) => {
            tableVotersCombined2[index] = tableVotersCombined2[index] || [];
            if (tableVotersCombined.includes(vote?.awardId)) {
              let foundElement = tableVotersCombined2[index].find(el=>el.awardId === vote?.awardId);
              if (foundElement) {
                foundElement.votesByAward = foundElement.votesByAward || [];
                foundElement.votesByAward.push(vote);
              }
            } else {
              tableVotersCombined.push(vote.awardId);
              let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
              tableVotersCombined2[index].push({
                numVotesAllowed: vote.numVotesAllowed,
                awardId: vote.awardId,
                votesByAward: [vote],
                id: uniqueId,
                customVotes: vote.customVotes,
                voteRank: vote.voteRank
              });
            }          
          })
          tableVotersCombined = [];
        })

        tableVotersCombined2 = tableVotersCombined2.map(a => {
          return {
            customVotes: a.customVotes,
            votes: {
              nominations : [...a],
            },
            expanded: [],
          }
        })

        noVotes.forEach( (el) => {
          tableVotersCombined2.find( item => {
            if(item.votes.nominations[0].votesByAward[0].voter.id === el.voter.id) {
              let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
              item.votes.nominations.push({numVotesAllowed: el.numVotesAllowed, awardId: el.awardId, votesByAward: [], id: uniqueId})
            }
          })
        })
        

        this.tables = tables
        // this.tablesVoters = tableVotersCombined2.filter(el => el.votes.nominations[0].awardId !== null);
        this.tablesVoters = tableVotersCombined2;
        this.ballot = ballot
      }
      else {
        // Error handling
      }

      this.loaded = true
    },
  },

  async created() {
    this.$root.updateTitle("Voting Results")
    
    this.getBallot()
  }
}
</script>

<style lang="scss">
.panel-with-no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
.table-font {
  font-size: 0.775rem;
}
</style>